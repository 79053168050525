/* styles.css */
.pdf-viewer-controls {
    margin-bottom: 10px;
  }
  
  .pdf-viewer-controls button {
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pdf-viewer-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pdf-container {
    border: 1px solid #ccc;
    padding: 10px;
    overflow: auto; /* Enable scrolling */
    position: relative;
    height: 500px; /* Set a fixed height for the container */
    cursor: grab;
  }
  
  .pdf-container:active {
    cursor: grabbing;
  }
  
  .pdf-content {
    position: relative;
  }
  