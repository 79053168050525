

/* /////////////////////////////---> START of Overwrite Region */

/* //For Modal */
.css-126xj0f {
  z-index: 30000 !important;
}

.css-13cymwt-control {
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  -webkit-box-align: center;
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  outline: 0px !important;
}

.css-t3ipsp-control {
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

.css-1nmdiq5-menu {
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

/* /////////////////////////////---> END of Overwrite Region */


/* /////////////////////////////---> START of Text Region */

.tp-text-white {
  color: #ffffff !important;
}

.tp-text-default {
  color: #525252 !important;
}

.tp-text-required {
  color: #d80000 !important;
}

.tp-text-primary {
  color: #1C1364 !important;
}

.tp-text-muted {
  color: #bcbcbc !important;
}

.tp-text-bold {
  font-weight: bold !important;
}

.tp-text-placeholder {
  color: #bcbcbc !important;
}

.tp-text-subtitle {
  color: #8c8c8c;
  font-weight: lighter !important;
}

.tp-text-header-primary {
  color: #1C1364 !important;
  font-weight: bold !important;
}

.tp-text-header-primary-lcp-ldp {
  color: rgb(177, 17, 22) !important;
  font-weight: bold !important;
}

.tp-text-header-error {
  color: rgb(177, 17, 22) !important;
  font-weight: bold !important;
}

/* /////////////////////////////---> END of Text Region */


/* /////////////////////////////---> START of Chip Region */

.tp-chip-success {
  color: #ffffff !important;
  background-color: #05a35c !important;
}

.tp-chip-info {
  color: #ffffff !important;
  background-color: #1C1364 !important;
}

.tp-chip-warning {
  color: #ffffff !important;
  background-color: #f9b115 !important;
}

.tp-chip-danger {
  color: #ffffff !important;
  background-color: #e55353 !important;
}

/* /////////////////////////////---> END of Chip Region */


/* /////////////////////////////---> END of Chip Region */

.tp-card-success {
  color: #ffffff !important;
  background-color: #05a35c !important;
}

.tp-card-info {
  color: #ffffff !important;
  background-color: #1C1364 !important;
}

.tp-card-warning {
  color: #ffffff !important;
  background-color: #f9b115 !important;
}

.tp-card-danger {
  color: #ffffff !important;
  background-color: #e55353 !important;
}

/* /////////////////////////////---> END of Chip Region */

/* /////////////////////////////---> START of Margin Region */

.tp-mb-10px {
  margin-bottom: 10px !important;
}

.tp-mb-15px {
  margin-bottom: 15px !important;
}

.tp-mb-20px {
  margin-bottom: 20px !important;
}

.tp-mb-30px {
  margin-bottom: 30px !important;
}

.tp-mt-10px {
  margin-top: 10px !important;
}

.tp-mt-15px {
  margin-top: 15px !important;
}

.tp-mt-30px {
  margin-top: 30px !important;
}

.tp-mlr-30px {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.tp-mlr-50px {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.tp-mlr-25px {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.tp-mtb-20px {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.tp-mtb-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.tp-mtb-0px {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.tp-my-20px {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

/* /////////////////////////////---> END of Margin Region */


/* /////////////////////////////---> START of Padding Region */

.tp-padding-50px {
  padding: 50px !important;
}

.tp-padding-15px {
  padding: 15px !important;
}

/* /////////////////////////////---> END of Padding Region */

/* /////////////////////////////---> START of Background Region */

.tp-bg-info {
  background-color: #1C1364 !important;
}

.tp-bg-success {
  background-color: #41B883 !important;
}

.tp-bg-warning {
  background-color: #f9b115 !important;
}

.tp-bg-danger {
  background-color: #e55353 !important;
}

.tp-bg-lightPrimary {
  background-color: #f2f9ff !important;
}

.tp-bg-disable {
  background-color: #ededed !important;
}

/* /////////////////////////////---> END of Background Region */

/* /////////////////////////////---> START of Colors Region */


/* /////////////////////////////---> END of Colors Region */

/* /////////////////////////////---> START of Buttons Region */

.tp-primary-icon-btn {
  color: #1C1364 !important;
}

.tp-danger-icon-btn {
  color: #d80000 !important;
}

.tp-primary-btn {
  color: #ffffff !important;
  background-color: #1C1364 !important;
}

.tp-danger-btn {
  color: #ffffff !important;
  background-color: #e55353 !important;
}

.tp-success-btn {
  color: #ffffff !important;
  background-color: #cf2e2e !important;
}

.tp-warning-btn {
  color: #ffffff !important;
  background-color: #f9b115 !important;
}

.tp-default-btn {
  background-color: #dddddd !important;
}

/* /////////////////////////////---> END of Buttons Region */

/* /////////////////////////////---> START of Cursor Region */

.tp-cursor-pointer {
  cursor: pointer !important;
}

/* /////////////////////////////---> END of Cursor Region */

/* /////////////////////////////---> START of Border Region */

.tp-border-success {
  border: #41B883 solid 1px !important;
}

.tp-border-info {
  border: #1C1364 solid 1px !important;
}

.tp-border-warning {
  border: #f9b115 solid 1px !important;
}

.tp-border-danger {
  border: #e55353 solid 1px !important;
}

.tp-border-hr_primary {
  border: #1C1364 solid 1px !important;
}

/* /////////////////////////////---> END of Border Region */

/* /////////////////////////////---> START of Screen Region */

.tp-hide-onScreen{
  display: none !important;
}

.components_cellWrapper__3Ogxw {
  display: none !important;
}
.TaskFormPage_taskIntro__3eL0d {
  display: none !important;
}

/* /////////////////////////////---> END of Screen Region */

/* /////////////////////////////---> START of Custom CSS Region */

.tp-table-btn{
  margin-top: 15px !important;
  padding-right: 10px;
  float: right;
}
/* styles.css */
.readOnlyInput {
  background-color: #f0f0f0; /* Light grey background */
  color: #595959; /* Dim text color */
  border: 1px solid #ced4da; /* Optional: specific border */
}

.readOnlyInput input {
  cursor: not-allowed; /* Change cursor to indicate no action can be taken */
}

/* BPI MS */

/* Button Primary */
.tp-custom-button-primary {
  color: #f0f0f0 !important;
  background: linear-gradient(90deg, rgba(186,26,31,1) 0%, rgba(205,46,50,1) 50%, rgba(226,106,109,1)) !important;
}

.tp-custom-button-primary:hover {
  color: #f0f0f0 !important;
  background: linear-gradient(90deg, rgba(177,17,22,1) 0%, rgba(177,17,22,1) 50%, rgba(177,17,22,1) 100%) !important;
}

.tp-custom-button-primary:disabled {
  color: #f0f0f0 !important;
  background: linear-gradient(90deg, rgba(240,176,178,1) 0%, rgba(240,176,178,1) 50%, rgba(240,176,178,1) 100%) !important;
}

/* Links */
.tp-custom-link-primary:link {
  color: rgb(177, 17, 22) !important;
  padding: 0.5em !important;
  border-radius: 0.5em !important;
}

.tp-custom-link-primary:hover {
  color: rgb(177, 17, 22) !important;
  padding: 0.5em !important;
  border-radius: 0.5em !important;
  background: rgb(255, 237, 237) !important;
}

.tp-custom-link-primary:visited {
  color: rgb(177, 17, 22) !important;
  padding: 0.5em !important;
  border-radius: 0.5em !important;
  background: rgb(224, 160, 162) !important;
}

.dropzone {
  border: 2px dashed #cccccc;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.dropzone p {
  margin: 0;
}

.dropzone:hover {
  background-color: #dfe4e9;
}




/* /////////////////////////////---> END of Custom CSS Region */